import { IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function ViewIconButton({handleClick}) {
  return (
    <>
        <IconButton
            aria-label="delete"
            size="medium"
            variant="contained"
            color="secondary"
            onClick={handleClick}
            sx={{
              "&:hover": {
                background: "none",
                transform: "scale(1.1)",
                transition: "0.3s",
              },
            }}
        >
            <VisibilityIcon fontSize="inherit" />
        </IconButton>
    </>

  )
}
