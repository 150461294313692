import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { loadUserData } from "./userSlice";
import baseURL from "./baseURL";

// registerUser
export const registerUser = (data)=>{
  return async (dispatch) => { // Return a function that takes 'dispatch'
      try {
          const response = await axios.post(`${baseURL}/register`, data)
          return response.data
      } catch (error) {
        throw error
      }
  }
}

// update single user
export const setUser = (sys_id, data)=>{
  return async (dispatch) => { // Return a function that takes 'dispatch'
    try {
      const response = await axios.post(`${baseURL}/update/${sys_id}`,data)
      return response
    } catch (error) {
      throw error
    }
  }
};


export const getUserData = (sys_id) => {
  return async (dispatch) => { // Return a function that takes 'dispatch'
    try {
      const res = await axios.get(`${baseURL}/user/${sys_id}`)
      dispatch(loadUserData(res.data))
    } catch (error) {
      throw error
    }
  }
}

export const getUsers = () => {
  return async function fetchData() {
    try {
        const response = await axios.get(`${baseURL}/users`)
        return response.data
    } catch (error) {
      throw error
    }
  }
}

export const getUser = (sys_id) => {
  return async function fetchData(){
    try {
        const response = await axios.get(`${baseURL}/user/${sys_id}`)
        return response.data
    } catch (error) {
      throw error
    }
  }
}

export const updateUser = createAsyncThunk("users/update", async (user, sys_id) => {
    const response = await axios.post(`${baseURL}/update/${sys_id}`, user)
    return response.data
});



// controller for delete
// needs access token from current user
export const deleteUser = (sys_id) =>{
  return async (dispatch)=>{
    try {
        const response = await axios.delete(`${baseURL}/delete/${sys_id}`)
        return response
    } catch (error) {
      throw error
    }
  }
}


export const getUserbyEmail = (data) => {
  return async function fetchData() {
    try {
        const response = await axios.get(`${baseURL}/findbyEmail`, data)
        return response
    } catch (error) {
      throw error
    }
  }
}

export const getUserbyEmailFilter = (email) => {
  return async function fetchData() {
    try {
        const response = await axios.get(`${baseURL}/load-user-filter?email=${email}`)
        return response
    } catch (error) {
      throw error
    }
  }
}