import React from 'react';
import { XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import useDashboardHooks from '../hooks/useDashboardHooks';



const CustomBarChart  = () => {
    
  const { barGraphData } = useDashboardHooks()

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={barGraphData}
          margin={{
            top: 40,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar name="Main" type="monotone" dataKey="main_branch" fill="#82ca9d"/>
          <Bar name="Store 1"  type="monotone" dataKey="store_1" fill="#C7C357" />
          <Bar name="Store 2"  type="monotone" dataKey="store_2" fill="#D56A5C" />
          <Bar name="Store 3"  type="monotone" dataKey="store_3" fill="##8884D8" />
        </BarChart>
      </ResponsiveContainer>
    );
  
}

export default CustomBarChart ;