import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

export default function StoreIcon({field}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="left"
            backgroundColor={
              field === "main_branch"
                ? colors.greenAccent[600]
                : colors.blueAccent[700]
            }
            borderRadius="4px"
          >
            {field === "main_branch" && <StoreMallDirectoryIcon />}
            {field === "store_1" && <ShoppingBagIcon />}
            {field === "store_2" && <ShoppingBagIcon />}
            {field === "store_3" && <ShoppingBagIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {
                field?.split("_")
                .join(" ")
                .toUpperCase()
              }
            </Typography>
        </Box>
    )
}
