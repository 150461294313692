import { Alert, AlertTitle, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AlertBox = ({handleCloseAlert, alert}) => {

    //severity="error"
    //severity="warning"
    //severity="info"
    //severity="success"

  return (
        <Box
        style={{ width:"50%" , position: 'fixed', top: "1rem", left: 0, right: 0, margin: "0 auto", zIndex: 9999}}
        >
            <Alert severity={alert.severity}>
                <AlertTitle>{alert.title}</AlertTitle>
                    {alert.message}
                <IconButton 
                    style={{ position: 'absolute', top: 0, right: 0 }}
                    onClick={handleCloseAlert}
                >
                    <CloseIcon />
                </IconButton>
            </Alert>
            
        </Box>
  );
};

export default AlertBox;
