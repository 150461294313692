import { Box, IconButton, Modal, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import TableList from "./tableList";
import useTransactionHook from "../../hooks/useTransactionHook";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import Invoice from "./invoice";
import ViewIconButton from "../../buttons/ViewIconButton";
import DeleteIconButton from "../../buttons/DeleteIconButton";
import AlertBox from "../../components/AlertBox";
import DialogBox from "../../components/DialogBox";
import StoreIcon from "../../icons/StoreIcon";

const Transactions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);
  const [recordID, setrecordID] = useState("");
  const [alert, setAlert] = useState(null);

  const { allData, removeTransaction } = useTransactionHook()

  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleActionClick = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setOpenModal(false);
  };

  const handleRemove = (rowId) => {
    setrecordID(rowId)
    setOpen(true)
  };

 
  const transformedData = allData ? allData.map(item => ({
    ...item,
    customer_fullname: item.customer.fullname,
    customer_phone_number: item.customer.phone_number,
    customer_email: item.customer.email,
    created_by: item.created_by.fullname
    // product_item: item.product_item.length > 1 ? `${item.product_item[0].name} ... +${item.product_item.length - 1} more` : item.product_item[0].name,

  })) : [];

  const columns = [
    // { field: "_id", headerName: "ID", hide: true },
    { field: "customer_fullname", headerName: "Name", flex: 1, cellClassName: "name-column--cell" },
    // { field: "customer_phone_number", headerName: "Phone Number", flex: 1 },
    { field: "customer_email", headerName: "Email", flex: 1 },
    { field: "product_item", headerName: "Items", flex: 1, hide: true  },
    { field: "store", headerName: "Store Branch", flex: 1,
      options : [
        { label : "Main Branch", value: "main_branch"},
        { label : "Store 1", value: "store_1"},
        { label : "Store 2", value: "store_2"},
        { label : "Store 3", value: "store_3"},
      ], 
      renderCell: (params) => {
        return (
          <StoreIcon field={params.row.store}/>
        );
      },
    },
    { field: "total_amount", headerName: "Total Amount", flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          ₱ {params.row.total_amount}
        </Typography>
      ),
    },
    { field: "date", headerName: "Date", flex: 1 },
    { field: "created_by", headerName: "Created by", flex: 1 },
    { field: "action", headerName: "Action", flex: 0.5,
      renderCell: (params) => {
        return (
          <>
          <ViewIconButton handleClick={() => handleActionClick(params.row)} />
          <DeleteIconButton handleClick={() => handleRemove(params.row._id)} />
          </>
        );
      },
    },
  ];

  // Conditionally filter the columns array based on a condition (e.g., remove the "Action" field)
  const formColumns = columns.filter(column => column.field !== "action");

  const handleCloseAlert = ()=>{
    setAlert(null)
  }

  const handleRemoveDialog = ()=>{
    removeTransaction(recordID, setAlert, setOpen)
  }

  return (
    <>
      <Box m="20px">
        <Header title="TRANSACTIONS" subtitle="List of transactions" />
        <TableList columns={columns} data={transformedData} />
      </Box>

      {alert && <AlertBox handleCloseAlert={handleCloseAlert} alert={alert} />}
      {open && <DialogBox open={open} setOpen={setOpen} handleRemove={handleRemoveDialog} />}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: '70vw',
            height: '90vh',
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
            }}
          >
            <CloseIcon />
          </IconButton>
          
          <Box
            sx={{
              gridColumn: "span 4",
              height: "100%",
              maxHeight: '80vh', // Set a maximum height for the Invoice
              // overflowY: 'auto', // Enable vertical scroll if content exceeds maxHeight
            }}
          >
            <Invoice 
              data={selectedRow} 
              columns={formColumns}
            />
          </Box>
        </Box>
      </Modal>
    </>

  );
};

export default Transactions;
