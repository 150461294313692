import { Button, useTheme } from "@mui/material";
import useRoleHooks from "../hooks/useRoleHooks";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { tokens } from "../theme";

export default function CreateNewButton({handleCreate}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { isAdmin } = useRoleHooks()

    return (
        <>  
            <Button
                disabled = {!isAdmin()}
                sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                }}
                onClick={handleCreate}
            >
                <PlaylistAddIcon sx={{ mr: "10px" }} />
                Create New 
            </Button>
        </>
    )
}
