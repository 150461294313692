

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../services/userAPIcalls';
import { loadUserData, logoutUser } from '../services/userSlice';
import { googleLogin } from '../services/loginAPIcalls';
import jwt_decode from "jwt-decode";

const useLoginApiHooks = () => { 

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleLoginAction = (data) =>{
    return dispatch(googleLogin(data))
        .then(response=>{
            if(response){
                loggedUserData(getTokenID())
                navigate("/portal");
            }
        })
        .catch(error => {
            console.error(error);
        });
  }
    const getTokenID = ()=>{
        const token = window.sessionStorage.getItem('petShop-access-token')
        const decoded = jwt_decode(token);
        return decoded.id
    }

    const loggedUserData = (tokenID) =>{
        if(tokenID !== undefined){
            return dispatch(getUser(tokenID))
            .then(response=>{
                dispatch(loadUserData(response))
            })
            .catch(error => {
                console.error(error);
            });
        }else{
            return Promise.resolve();
        }
    }



    // logout service
    const logout = ()=>{
        window.sessionStorage.removeItem('petShop-access-token')
        dispatch(logoutUser())
        navigate('/login');
        return {msg:'Logout Successfully'}
    }


  return {
    // findUserByEmail,
    googleLoginAction,
    loggedUserData,
    logout,
    getTokenID
  };
};

export default useLoginApiHooks;
