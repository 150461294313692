
import { useSelector } from "react-redux";

const useRoleHooks = () => { 

    const userInfo = useSelector((state) => state.user.userInfo);

    const isAdmin = ()=>{
      // return true if user is admin or manager
      if(userInfo.account_type === "admin" || userInfo.account_type === "manager"){
        return true
      }else{
        return false
      }
    }

    return {
        isAdmin,
    };
};

export default useRoleHooks;
