import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import thunkMiddleware from 'redux-thunk'; // Import Redux Thunk

export default configureStore({
  reducer: {
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware), // Apply Redux Thunk middleware
});

