import { useState } from "react";
import { Box, useTheme  } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";

const TableList = ({columns, data}) => {

  const [selectedRow, setSelectedRow] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleActionClick = (row) => {
    setSelectedRow(row);
  };

  return (
    <>
      <Box
        m="0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          checkboxSelection={false}
          sx={{
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
          getRowId={(row) => row.id} // Pass the getRowId function
          onRowClick={(params) => handleActionClick(params.row)}
          selectionModel={selectedRow ? [selectedRow.id] : []}
        />

      </Box>

    </>
  );
};

export default TableList;
