

import { useDispatch, useSelector } from 'react-redux';
// import { useEffect, useState } from 'react';
import { deleteData, getProducts, registerProduct, setProduct } from '../services/productAPIcalls';
import { useEffect, useState } from 'react';


const useProductHooks = () => { 

    const userInfo = useSelector((state) => state?.user.userInfo);

    const dispatch = useDispatch();
    const [allProductsData, setAllProductsData] = useState([]);
    const [storeProductsData, setStoreProductsData] = useState([]);
    const [updateTrigger, setUpdateTrigger] = useState(0);

    useEffect(()=>{
        dispatch(getProducts()) // Call getTransactions to dispatch the action
        .then(response=>{
            const storeFiltered = response.filter((item)=> item.store === userInfo?.store)
            setAllProductsData(response) // Update the state with the response data
            setStoreProductsData(storeFiltered) // Update the state with the response data
        })
        .catch(error => {
            console.error(error);
        });
    }, [dispatch, updateTrigger, userInfo])

    const handleFormSubmitHook = (data, setAlert, setOpenModal) =>{
        if(data._id === undefined){
            // console.log(data)
            return dispatch(registerProduct(data))
                .then(response=>{
                    
                    var alert = {
                        severity : "success",
                        title : "Success",
                        message : "Product created successfully!"
                    }
                    setOpenModal(false)
                    setAlert(alert)
                    setUpdateTrigger(prevTrigger => prevTrigger + 1); // Update the trigger state
                })
                .catch(error => {
                    var alert = {
                        severity : "error",
                        title : "Error",
                        message : error
                    }
                    setOpenModal(false)
                    setAlert(alert)
                });
        }
        else {
            return dispatch(setProduct(data._id, data))
            .then(response=>{
                
                var alert = {
                    severity : "success",
                    title : "Record Updated",
                    message : "Product updated successfully!"
                }
                setOpenModal(false)
                setAlert(alert)
                setUpdateTrigger(prevTrigger => prevTrigger + 1); // Update the trigger state
            })
            .catch(error => {
                var alert = {
                    severity : "error",
                    title : "Error",
                    message : error
                }
                setOpenModal(false)
                setAlert(alert)
            });
        }

    }

    const removeProduct = (recordID, setAlert, setOpen) =>{
        return dispatch(deleteData(recordID))
            .then(response=>{
                
                var alert = {
                    severity : "success",
                    title : "Product Removed",
                    message : "Product removed successfully!"
                }
                setOpen(false)
                setAlert(alert)
                setUpdateTrigger(prevTrigger => prevTrigger + 1); // Update the trigger state
            })
            .catch(error => {
                console.error(error);
            });
    }

    const hasDuplicateField = (field, value) => {
        return allProductsData && allProductsData.find((product) => product[field] === value && product.store === userInfo?.store);
    };



    return {
        handleFormSubmitHook,
        removeProduct,
        allProductsData,
        storeProductsData,
        hasDuplicateField
    };
};

export default useProductHooks;
