
import jwtDecode from "jwt-decode";
import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoginApiHooks from "../../hooks/useLoginHook";
import { useDispatch } from "react-redux";
import { userlogin } from "../../services/loginAPIcalls";
import { getUser } from "../../services/userAPIcalls";
import { loadUserData } from "../../services/userSlice";


export default function Login () {

    const navigate = useNavigate();
    const client_id = "232213362716-b542tj5d52j4ob1ukvq0jglcd3o1em0r.apps.googleusercontent.com"
    const dispatch = useDispatch();

    const { googleLoginAction, getTokenID } = useLoginApiHooks();
    const [formData, setFormData] = useState({});
    const [errorMessage, setError] = useState("");
    
    const handleCallbackResponse = (response) =>{
        const userObject = jwtDecode(response.credential)

        googleLoginAction(userObject)
    }

    const handleSubmit = (e) =>{
        e.preventDefault(); 

        dispatch(userlogin(formData))
            .then(response=>{
                const tokenID = getTokenID()
                if(tokenID !== undefined){
                    return dispatch(getUser(tokenID))
                    .then(response=>{
                        dispatch(loadUserData(response))
                        navigate("/portal")
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
            })
            .catch(error => {
                setError("Error username/password")
            });
    }

    const handleChange = (e) => {
        setError('')
        const { name, value } = e?.target || {};
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const initializeGoogleSignIn = () => {
        /* global google */
        google.accounts.id.initialize({
          client_id: client_id,
          callback: handleCallbackResponse,
        });
        google.accounts.id.renderButton(document.getElementById("signInDiv"), {
          theme: "outline",
          size: "large",
          hl: "en",
          className: "custom-signin-button",
        });
    };

    useEffect(() => {
        initializeGoogleSignIn();
        // eslint-disable-next-line
      },[]);

    return(
        <div id="login">
            <form onSubmit={handleSubmit}> 
                <div className="container login-wrap">
                    <h1>Welcome back</h1>
                    {errorMessage && 
                        <>{errorMessage} test</>
                    }
                    <div className="input-wrap">
                        <input 
                            placeholder="Email Address" 
                            type="email"
                            name="email"
                            // value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-wrap">
                        <input 
                            placeholder="Password" 
                            type="password"
                            name="password"
                            // value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    
                    <button type="submit" className="input-wrap continue-btn">Continue</button>
                    <div className="signup-wrap">
                        <p>Don't have an account? <Link className="sign-up" to={'/register'}>Sign up</Link></p>
                    </div>
                    <div className="or-divider">
                        <span className="or-text">OR</span>
                    </div>

                    {/* <span>or</span> */}
                    <div >
                        <div id="signInDiv"></div>
                    </div>
                </div>
            </form>
        </div>
    )
}

