import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useLoginApiHooks from "../hooks/useLoginHook";
import { useDispatch } from "react-redux";
import { getUsers } from "../services/userAPIcalls";

import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Loader from "../scenes/loader";

function PublicLayout() {

  const [theme, colorMode] = useMode();
  const { loggedUserData, getTokenID } = useLoginApiHooks();
  const [data, setData] = useState()

  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(()=>{
      const route =()=>{
          // const token = localStorage.getItem('x-access-token')
          const token = window.sessionStorage.getItem('petShop-access-token')
          return token ? true : false
      }

      dispatch(getUsers())
      .then(response=>{
          setData(response)

          if(!route()){
              navigate('/login')
          }else{
              loggedUserData(getTokenID())
          }
      })
      // eslint-disable-next-line
  },[])

  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
    <CssBaseline />

    <div id="public-layout">
      {!data && <Loader />}
      {data &&
        <Outlet />
      }
    </div>

    </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default PublicLayout;
