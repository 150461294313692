
import axios from "axios";
import baseURL from "./baseURL";


// update single user
export const googleLogin = (userCredential)=>{
    return async (dispatch) => { // Return a function that takes 'dispatch'
      try {
        const response = await axios.post(`${baseURL}/googlelogin`,userCredential)

        if(response.data.token){
            // localStorage.setItem("petShop-access-token", response.data.token)
            window.sessionStorage.setItem("petShop-access-token", response.data.token);
            return response.data.token
        }else{
          return Promise.resolve();
        }
        
      } catch (err) {
        // console.log(err)
      }
    }
};

// update single user
export const userlogin = (userCredential)=>{
    return async (dispatch) => { // Return a function that takes 'dispatch'
      try {
        const response = await axios.post(`${baseURL}/login`,userCredential)
        if(response.data.token){
            // localStorage.setItem("petShop-access-token", response.data.token)
            window.sessionStorage.setItem("petShop-access-token", response.data.token);
            return response.data.token
        }else{
          // If the response doesn't contain a token, consider it an error
          throw new Error("Token not found in the response");
        }
      } catch (err) {
        return Promise.reject("Login failed. Please try again.");
      }
    }
};

// register user
export const registerUser = (data)=>{
    return async (dispatch) => { // Return a function that takes 'dispatch'
      try {
        const response = await axios.post(`${baseURL}/register`,data)
        return response
      } catch (err) {
        return Promise.reject(err);
      }
    }
};
