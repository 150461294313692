import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import useDashboardHooks from '../hooks/useDashboardHooks';



const CustomLineChart  = () => {
    
  const { lineGraphData } = useDashboardHooks()

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={lineGraphData}
          margin={{
            top: 40,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line name="Main" type="monotone" dataKey="main_branch" stroke="#82ca9d" activeDot={{ r: 8 }}/>
          <Line name="Store 1"  type="monotone" dataKey="store_1" stroke="#D2C557" activeDot={{ r: 8 }} />
          <Line name="Store 2"  type="monotone" dataKey="store_2" stroke="#D56A5C" />
          <Line name="Store 3"  type="monotone" dataKey="store_3" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    );
  
}

export default CustomLineChart ;