import { IconButton } from "@mui/material";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import useRoleHooks from "../hooks/useRoleHooks";

export default function EditIconButton({handleClick}) {

  const { isAdmin } = useRoleHooks()

  return (
    <>
      <IconButton
        disabled = {!isAdmin()}
        size="medium"
        variant="contained"
        color="secondary"
        onClick={handleClick}
        sx={{
          "&:hover": {
            background: "none",
            transform: "scale(1.1)",
            transition: "0.3s",
          },
        }}
        >
        <DriveFileRenameOutlineIcon fontSize="inherit" />
      </IconButton>
    </>

  )
}
