import { IconButton } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { red } from "@mui/material/colors";
import useRoleHooks from "../hooks/useRoleHooks";

export default function DeleteIconButton({handleClick}) {

  const { isAdmin } = useRoleHooks()

  return (
    <>
      <IconButton
        disabled = {!isAdmin()}
        aria-label="delete"
        size="large"
        variant="contained"
        color="secondary"
        onClick={handleClick}
        sx={{
          "&:hover": {
            background: "none",
            color: red[500], // Use the red color from MUI
            transform: "scale(1.1)",
            transition: "0.3s",
          },
        }}
      >
        <DeleteForeverIcon fontSize="inherit" />
      </IconButton>
    </>

  )
}
