import React from 'react';
import Typography from '@mui/material/Typography';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import Header from '../components/Header';
import { Box } from '@mui/material';


const Invoice = ({customer, data, date, totalAmount, store}) => {

  return (
    <Box>
      {/* <Header subtitle="INVOICE" /> */}
      {/* <Typography margin="0" variant="h6" align="left" gutterBottom>
        NAME : {customer ? 
                customer.fullname ? customer.fullname : customer.username
                : ""
              }
      </Typography> */}
      <Typography margin="0" variant="h6" align="left" gutterBottom>
        OR# : 
      </Typography>
      <Typography margin="0 0" variant="h6" align="left" gutterBottom>
        Date : {date}
      </Typography>
      <Typography margin="0 0 10px 0" variant="h6" align="left" gutterBottom>
        Branch : {store?.split("_")
                .join(" ")
                .toUpperCase()}
      </Typography>
      {/* <TableContainer component={Paper} style={{ height: '350px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>₱{item.unitPrice}</TableCell>
                <TableCell>₱{item.quantity * item.unitPrice}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      
      <Typography margin="20px 0 0 0" variant="h5" align="left" gutterBottom>
        TOTAL : ₱ {totalAmount}
      </Typography>
    </Box>
  );
};

export default Invoice;
