
import { createSlice } from "@reduxjs/toolkit";
import { updateUser } from "./userAPIcalls";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: {
      username: null,
      name: null,
      email: null,
      account_type: null,
      store: null,
      loaded: null,
    },
    pending: null,
    error: null,
  },
  reducers: {
    loadUserData: (state, action)=>{
      state.userInfo = action.payload;
      state.loaded = true;
    },
    logoutUser: (state)=>{
      state.userInfo = null;
      state.loaded = false;
    },
  },
  
  extraReducers: (builder) => {
      builder
      .addCase(updateUser.pending, (state) => {
          state.pending = true;
          state.error = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
          state.userInfo = action.payload;
          state.pending = false;
      })
      .addCase(updateUser.rejected, (state) => {
          state.pending = null;
          state.error = true;
      });
  },
});

export const { loadUserData,logoutUser } = userSlice.actions;

export default userSlice.reducer;
