
import axios from "axios";
import baseURL from "./baseURL";

// registerTransaction
export const registerTransaction = (data)=>{
    return async (dispatch) => { // Return a function that takes 'dispatch'
        try {
            const response = await axios.post(`${baseURL}/register-transaction`, data)
            return response.data
        } catch (error) {
            throw error
        }
    }
}


export const getTransactions = () => {
  return async function fetchData() {
    try {
        const response = await axios.get(`${baseURL}/load-transactions`)
        return response.data
    } catch (error) {
      throw error
    }
  }
}

// update setTransaction
export const setTransaction = (sys_id, data)=>{
  return async (dispatch) => { // Return a function that takes 'dispatch'
    try {
      const response = await axios.post(`${baseURL}/update-transaction/${sys_id}`,data)
      return response
    } catch (error) {
      throw error
    }
  }
};

// controller for delete
// needs access token from current user
export const deleteData = (sys_id) =>{
  return async (dispatch)=>{
    try {
        const response = await axios.delete(`${baseURL}/delete-transaction/${sys_id}`)
        return response
    } catch (error) {
        throw error
    }
  }
}


export const getTransaction = (sys_id) => {
  return async function fetchData(){
    try {
        const response = await axios.get(`${baseURL}/load-transaction/${sys_id}`)
        return response.data
    } catch (error) {
        throw error
    }
  }
}




