
import { Routes, Route } from "react-router-dom";
import './App.scss';
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import { BrowserRouter } from "react-router-dom";
import Calendar from "./scenes/calendar/calendar";
import Products from "./scenes/products";
import Login from "./scenes/signin/login";
import PrivateLayout from "./layouts/PrivateLayout";
import PublicLayout from "./layouts/PublicLayout";
import Register from "./scenes/signin/register";
import Transactions from "./scenes/transactions";
import POSPortal from "./portal";

function App() {
  return (
    <BrowserRouter>
      <Routes >
        <Route path="/" element={<PublicLayout />}>

          <Route path="portal" element={<POSPortal />}></Route>

          <Route path="login" element={<Login />}></Route>
          <Route path="register" element={<Register />}></Route>
      
          <Route path="admin" element={<PrivateLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="team" element={<Team />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="form" element={<Form />} />
            <Route path="bar" element={<Bar />} />
            <Route path="pie" element={<Pie />} />
            <Route path="line" element={<Line />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="products" element={<Products />} />
            <Route path="transactions" element={<Transactions />} />
          </Route>{/* End PrivateLayout */}

        </Route>{/* End PublicLayout */}

      </Routes >
    </BrowserRouter>
  );
}

export default App;
