import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

const Form = ({data, columns}) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Conditionally filter the columns array based on a condition (e.g., remove the "Action" field)
    const inputFields = columns.filter(column => column.field !== "product_item");

    const productColumns = [
        { field: "name",  headerName: "Name",   flex: 1,  cellClassName: "name-column--cell", },
        { field: "quantity",  headerName: "Quantity",   flex: 1,  },
        { field: "unitPrice",  headerName: "Price",   flex: 1,  
            renderCell: (params) => (
                <Typography color={colors.greenAccent[500]}>
                ₱ {params.row.unitPrice}
                </Typography>
            ),
        },
        { field: "amount",  headerName: "Amount",   flex: 1, 
            renderCell: (params) => (
                <Typography color={colors.greenAccent[500]}>
                ₱ {params.row.amount}
                </Typography>
            ),
        },
    ]

    return (
        <Box 
            m="10px"
            height="100%" 
            // overflow="hidden"
        >
        <Header subtitle="Transaction details." />
        
            <Box
                display="grid"
                gap="30px"
                // height="30vh"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  }}
            >
                {inputFields.map((item)=> 
                    item.field === "image" ? (
                    <Box sx={{ gridColumn: "span 4" }} key={item.field}>
                        <input
                        type="file"
                        accept="image/*"
                        style={{ display: "block", marginBottom: "10px" }}
                        />
                        <img
                        alt="profile-user"
                        width="200px"
                        height="200px"
                        src={data[item.field] || "../../assets/default.jpg"}
                        style={{ cursor: "pointer" }}
                        />
                    </Box>

                    ) : item.options ? (
                    <FormControl 
                        key={item.field}
                        fullWidth
                        variant="filled"
                        sx={{ gridColumn: "span 2" }}
                    >
                        <InputLabel id="demo-simple-select-error-label">{item.headerName}</InputLabel>
                        <Select
                        labelId="demo-simple-select-error-label"
                        id="demo-simple-select"
                        name={item.field}
                        value={data[item.field]}
                        label={item.headerName}
                        inputProps={{
                          readOnly: true,
                        }}
                        >
                        {item.options.map((optionItem, index)=>(
                            <MenuItem key={index} value={optionItem.value}>{optionItem.label}</MenuItem>
                        ))}
                        
                        </Select>
                    </FormControl>

                    ) : (
                    <TextField
                        key={item.field}
                        fullWidth
                        variant="filled"
                        type="text"
                        label={item.headerName}
                        value={data[item.field]}
                        name={item.field}
                        InputProps={{
                            readOnly: true, // Make the TextField read-only
                            startAdornment: item.field === "total_amount" ? <InputAdornment position="start">₱</InputAdornment> : null,
                        }}
                        sx={{ gridColumn: "span 2" }}
                    />
                    )
                )}
            </Box>
            
            <Box  // data table 
                m="20px 0 0 0"
                sx={{
                    height: "50vh",
                    overflow: "auto",
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                },
                }}
            >
                <DataGrid
                    rows={data.product_item}
                    columns={productColumns}
                    checkboxSelection={false}
                    // autoHeight
                    sx={{
                        "& .MuiDataGrid-row": {
                        cursor: "pointer",
                        },
                    }}
                    getRowId={(row) => row._id}
                />
            </Box>
        </Box>
    );
};


export default Form;
