
import {  Box, IconButton, Modal, Typography, useTheme } from "@mui/material";
import TableList from "../form/tableList";
import Header from "../../components/Header";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import UpdateForm from "../form/updateModal";
import DialogBox from "../../components/DialogBox";
import AlertBox from "../../components/AlertBox";
import useUserHooks from "../../hooks/useUserHooks";

import EditIconButton from "../../buttons/EditIconButton";
import DeleteIconButton from "../../buttons/DeleteIconButton";
// import CreateNewButton from "../../buttons/CreateNewButton";

import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { handleFormSubmitHook, removeUser, guestData } = useUserHooks()

  const tableName = "users";
  const [open, setOpen] = useState(false);
  const [recordID, setrecordID] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [alert, setAlert] = useState(null);

  
  const columns = [
    { field: "_id", headerName: "ID", flex: 0.5, hide:true },
    // { field: "username", headerName: "User Name", flex: 1 },
    { order: 10, field: "fullname", headerName: "Full Name", flex: 1, cellClassName: "name-column--cell", },
    { order: 30, field: "store", headerName: "Store Branch", flex: 1,  hide:true, readOnly:true,
      options : [
        { label : "Main Branch", value: "main_branch"},
        { label : "Store 1", value: "store_1"},
        { label : "Store 2", value: "store_2"},
        { label : "Store 3", value: "store_3"},
      ], 
    },
    { order: 11, field: "email", headerName: "Email", flex: 1},
    { order: 20, field: "phone_number", headerName: "Phone Number", flex: 1, },
    { order: 21, field: "address", headerName: "Address", flex: 1, },
    { order: 31, field: "account_type", headerName: "Account Type", flex: 1,
      options : [
        { label : "Manager", value: "manager"},
        { label : "Admin", value: "admin"},
        { label : "Employee", value: "employee"},
        { label : "User", value: "user"},
      ],
      renderCell: ({ row: { account_type } }) => {
        return (
          <Box
            width="80%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              account_type === "admin" || account_type === "manager"
                ? colors.greenAccent[600]
                : colors.blueAccent[700]
            }
            borderRadius="4px"
          >
            {account_type === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {account_type === "manager" && <SecurityOutlinedIcon />}
            {account_type === "user" && <FaceOutlinedIcon />}
            {account_type === "employee" && <SupervisedUserCircleOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {account_type.toUpperCase()}
            </Typography>
          </Box>
        );
      },
    },
    { order: 70, field: "password", headerName: "Password", flex: 1, hide:true },
    { order: 80, field: "passwordCheck", headerName: "Confirm Password", flex: 1, hide:true},

    { field: "action", headerName: "Action", flex: 1,
      renderCell: (params) => {
        return (
        <>
          <EditIconButton handleClick={() => handleActionClick(params.row)}/>
          <DeleteIconButton handleClick={() => handleRemove(params.row._id)} />
        </>
        );
      },
    },

  ];

  // Conditionally filter the columns array based on a condition (e.g., remove the "Action" and "_id" field)
  const createNewColumns = columns.filter(column => column.field !== "action" && column.field !== "_id")
  const updateColumns = columns.filter(column => column.field !== "action" && column.field !== "password" && column.field !== "passwordCheck" )
  // const updateColumns = columns.filter(column => column.field !== "action" && column.field !== "password" && column.field !== "passwordCheck" && column.field !== "email")

  const handleActionClick = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setOpenModal(false);
  };

  const handleRemove = (rowId) => {
    setrecordID(rowId)
    setOpen(true)
  };

  const handleRemoveDialog = ()=>{
    removeUser(recordID, setAlert, setOpen)
  }

  // const handleCreate = ()=>{
  //   setSelectedRow(null);
  //   setOpenModal(true);
  // }

  const handleFormSubmit = (values) => {
    handleFormSubmitHook(values, setAlert, setOpenModal)
  };

  const handleCloseAlert = ()=>{
    setAlert(null)
  }

  return (
    <>
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="CONTACTS" subtitle="List of Contacts for Future Reference." />

        {/* <Box>
          <CreateNewButton handleCreate={handleCreate}/>
        </Box> */}
      </Box>
      <TableList columns={columns} data={guestData ? guestData : []}/>
    </Box>

    {alert && <AlertBox handleCloseAlert={handleCloseAlert} alert={alert} />}
    {open && <DialogBox open={open} setOpen={setOpen} handleRemove={handleRemoveDialog} />}
    

    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: '70vw',
          height: '90vh',
        }}
      >
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          <CloseIcon />
        </IconButton>
        
        <Box  sx={{ gridColumn: "span 4" }} >
          <UpdateForm
            initialValues={selectedRow ? selectedRow : null} 
            columns={selectedRow ? updateColumns : createNewColumns}
            handleFormSubmit={handleFormSubmit}
            tableName={tableName}
          />
        </Box>
      </Box>
    </Modal>
    </>
  );
};

export default Contacts;

