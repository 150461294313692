import React, { useState } from "react";
import { Box, TextField, Autocomplete } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import QueueIcon from '@mui/icons-material/Queue';

const ProductSelect = ({ options, InputLabel, setData, data, errors, setErrors}) => {

  const [quantity, setQuantity] = useState("");
  const [product, setProduct] = useState(null);
  const [limit, setLimit] = useState(null);

  const handleQuantityChange = (event) => {
    var value = event.target.value;
    setQuantity(value);
    setErrors({...errors, quantity : ""});

    if(value && value > parseInt(limit)){
      setErrors({...errors, quantity : `Exceeds the current stocks. Current instock ${limit}`});
    }
  };

  const handleAdd = () => {

    const isDataValid = handleValidation();

    if (isDataValid) {

      const newItem = {
        id: data.length,
        store: product.store,
        product: product._id,
        name: product.product_name,
        unitPrice: product.price,
        amount: product.price * quantity,
        quantity: parseInt(quantity, 10),
      };
  
      // Check if a product with the same ID already exists in the data array
      const existingProduct = data.findIndex((item) => item.product === newItem.product);

      if (existingProduct !== -1) {
        // If the product already exists, update its quantity
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[existingProduct].quantity = newItem.quantity;
          updatedData[existingProduct].amount = newItem.amount;
          return updatedData;
        });
      } else {
        // If the product doesn't exist, add the new item to the data array
        setData((prevData) => [...prevData, newItem]);
      }
      
      // Reset product and quantity
      setProduct(null);
      setQuantity("");
      setErrors({})
    }

  };

  const handleSelectProduct = (newValue)=>{


    if(newValue){
      const existingSelectedProduct = data.findIndex((item) => item.product === newValue._id);

      if(existingSelectedProduct !== -1){
         // If the product already exists, update its quantity
        setQuantity(data[existingSelectedProduct].quantity);
      }else{
        setQuantity("");
      }
      
      setLimit(newValue ? newValue.quantity : "")
      setProduct(newValue)
      setErrors({...errors, product : ""});

    }

    if(newValue === null){
      setProduct(newValue)
      setQuantity("");
      setErrors({...errors, product : "",quantity : ""});
    }

  }

  const handleValidation = ()=>{
    const validationErrors = {};

    if (!quantity) {
      validationErrors.quantity = "Quantity is required.";
    }else if (isNaN(quantity)) {
      validationErrors.quantity = "Quantity must be a number.";
    }else if(quantity > parseInt(limit)){
      validationErrors.quantity = `Exceeds the current stocks. Current instock ${limit}`;
    }
  
    if (!product) {
      validationErrors.product = "Product is required.";
    }
  
    setErrors(validationErrors);

    // Check if there are any validation errors
    return Object.keys(validationErrors).length === 0;
  }
  
  const disableOptions = options.reduce((previous, current)=> {
    if(current.quantity === "0"){
      previous.push(current)
    }
    return previous
  },[])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAdd();
    }
  };

  const handleArrowKeyPress = (event) => {
    if (event.key === 'ArrowUp') {
      // Increment quantity when Arrow Up is pressed
      setQuantity((prevQuantity) => (parseInt(prevQuantity, 10) || 0) + 1);
    } else if (event.key === 'ArrowDown') {
      // Decrement quantity when Arrow Down is pressed
      setQuantity((prevQuantity) => Math.max((parseInt(prevQuantity, 10) || 0) - 1, 0));
    }
  };

  return (
    <Box 
      display="flex"
      onKeyDown={handleKeyDown}
    >

      <Autocomplete
        // filterOptions={(x) => x}
        disablePortal
        sx={{ width: "100%", pr: 2 }}
        fullWidth
        options={options}
        value={product}
        onChange={(event, newValue) => {
          handleSelectProduct(newValue)
        }}
        getOptionDisabled={(option) =>
          disableOptions.some((disabledOption) => disabledOption._id === option._id)
        }
        getOptionLabel={(option) => `${option.product_name} (${option.product_code})`} // Customize the label to include multiple fields
        isOptionEqualToValue={(option, value) => option._id === value?._id}
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            {option.product_name} - {option.product_code}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={InputLabel}
            variant="filled"
            error={!!errors.product} // Set error state based on error message
            helperText={errors.product} // Display the error message
          />
        )}
      />
      <TextField
        variant="filled"
        type="text"
        label="Quantity"
        onChange={handleQuantityChange}
        onKeyDown={handleArrowKeyPress} // Listen for arrow key presses
        value={quantity}
        name="quantity"
        sx={{ width: "15%" }}
        error={!!errors.quantity} // Set error state based on error message
        helperText={errors.quantity} // Display the error message
        autoComplete="off" // Disable browser autocomplete
      />

      <IconButton
        size="large"
        variant="contained"
        color="secondary"
        onClick={handleAdd}
        disabled={!product} // Disable the button when there is no selected product
        sx={{
          "&:hover": {
            background: "none",
            color: "green",
            transform: "scale(1.1)",
            transition: "0.3s",
          }
        }}
      >
        <QueueIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export default ProductSelect;
