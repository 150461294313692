

import { useDispatch } from 'react-redux';
import { deleteData, getTransactions, registerTransaction } from '../services/transactionAPIcalls';
import { useEffect, useState } from 'react';
import { setProduct } from '../services/productAPIcalls';
import useProductHooks from './useProductHooks';


const useTransactionHook = () => { 

    const dispatch = useDispatch();
    const [allData, setAllData] = useState();
    const [recentData, setRecentData] = useState();
    const { allProductsData } = useProductHooks()
    const [updateTrigger, setUpdateTrigger] = useState(0);

    useEffect(()=>{
        dispatch(getTransactions()) // Call getTransactions to dispatch the action
        .then(response=>{

            setAllData(response) // Update the state with the response data
            recentTransactions(response, setRecentData) // Update recentTransactions with the response data

        })
        .catch(error => {
            console.error(error);
        });
    }, [dispatch, updateTrigger])

    
    const createTransaction = (data, setAlert, setData, setCustomer) =>{
        return dispatch(registerTransaction(data))
            .then(response=>{
                var alert = {
                    severity : "success",
                    title : "Success",
                    message : "Transaction submitted successfully!"
                }
                setAlert(alert)
                setData([])
                // setCustomer(null)
                updateProduct(data) // Updates product stocks
            })
            .catch(error => {
                var alert = {
                    severity : "error",
                    title : "Error",
                    message : error
                }
                setAlert(alert)
                setData([])
                // setCustomer(null)
            });
    }

    const updateProduct = (data)=> {
        const productQuantities = data.product_item.reduce((result, item) => {
            const { product, quantity } = item;
          
            const existingProduct = result.find((p) => p.product === product);
          
            if (existingProduct) {
                existingProduct.quantity += parseInt(quantity, 10);
            } else {
              result.push({ product, quantity: parseInt(quantity, 10) });
            }
          
            return result;
          }, []);
          
          productQuantities.map((item)=>{

            const existingProduct = allProductsData.find((product) => product._id === item.product);

            const updateItem = {
                quantity : parseInt(existingProduct.quantity, 10) - item.quantity
            }
            return dispatch(setProduct(item.product, updateItem))
                .then(response=>{
                    // Handle success
                })
                .catch(error => {
                    // Handle error
                });
          })
    }

    const removeTransaction = (recordID, setAlert, setOpen) =>{
        return dispatch(deleteData(recordID))
            .then(response=>{
                
                var alert = {
                    severity : "success",
                    title : "Product Removed",
                    message : "Product removed successfully!"
                }
                setOpen(false)
                setAlert(alert)
                setUpdateTrigger(prevTrigger => prevTrigger + 1); // Update the trigger state
            })
            .catch(error => {
                console.error(error);
            });
    }

    const recentTransactions = (data, setRecentData)=>{

        // Sort transactions by created_on in descending order
        const sortedTransactions = data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));

        // Get the latest 4 transactions
        const latestTransactions = sortedTransactions.slice(0, 10);

        setRecentData(latestTransactions)
    }

    return {
        allData,
        recentData,
        createTransaction,
        removeTransaction,
    };
};

export default useTransactionHook;
