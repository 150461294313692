
import { Box, useTheme  } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";


const TableList = ({columns, data}) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.date); // Replace 'date' with the actual field name for your date column
    const dateB = new Date(b.date);
  
    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      <Box
        m="20px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={sortedData}
          columns={columns.filter((column) => !column.hide)}
          components={{ Toolbar: GridToolbar }}
          checkboxSelection={false}
          sx={{
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
          getRowId={(row) => row._id} // Pass the getRowId function
        />
      </Box>

    </>
  );
};

export default TableList;
