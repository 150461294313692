
import {  Box, IconButton, Modal, Typography } from "@mui/material";
import TableList from "../form/tableList";
import Header from "../../components/Header";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import UpdateForm from "../form/updateModal";
import DialogBox from "../../components/DialogBox";
import useProductHooks from "../../hooks/useProductHooks";
import AlertBox from "../../components/AlertBox";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import EditIconButton from "../../buttons/EditIconButton";
import DeleteIconButton from "../../buttons/DeleteIconButton";
import CreateNewButton from "../../buttons/CreateNewButton";
import StoreIcon from "../../icons/StoreIcon";


const Products = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { handleFormSubmitHook, removeProduct, storeProductsData } = useProductHooks()

  const tableName = "products";
  const [open, setOpen] = useState(false);
  const [recordID, setrecordID] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [alert, setAlert] = useState(null);

  const columns = [
    { field: "_id", headerName: "ID", flex: 0.5, hide:true },
    { order: 10, field: "product_name", headerName: "Product Name", flex: 1, cellClassName: "name-column--cell", },
    { order: 20, field: "product_code", headerName: "Product Code", flex: 1, },
    { order: 30, field: "description", headerName: "Description", flex: 1,  },
    { order: 40, field: "sku", headerName: "SKU", flex: 1,  },
    { order: 15, field: "store", headerName: "Store Branch", flex: 1.3,
      options : [
        { label : "Main Branch", value: "main_branch"},
        { label : "Store 1", value: "store_1"},
        { label : "Store 2", value: "store_2"},
        { label : "Store 3", value: "store_3"},
      ],  
      renderCell: (params) => {
        return (
          <StoreIcon field={params.row.store}/>
        );
      },
    },
    { order: 60, field: "category", headerName: "Category", flex: 1, 
      options : [
        { label : "Dog Food", value: "Dog Food"},
        { label : "Cat Food", value: "Cat Food"},
        { label : "Device", value: "Device"},
        { label : "Medicine", value: "Medicine"},
        { label : "Others", value: "Others"},
      ]
    },
    { order: 70, field: "price", headerName: "Price", flex: 1,  
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          ₱ {params.row.price}
        </Typography>
      ),
    },
    { order: 80, field: "quantity", headerName: "Stock", flex: 1,  },
    { order: 90, field: "action", headerName: "Action", flex: 1,
      renderCell: (params) => {
        return (
        <>
          <EditIconButton handleClick={() => handleEdit(params.row)}/>
          <DeleteIconButton handleClick={() => handleRemove(params.row._id)} />
        </>
        );
      },
    },

  ];

  // Conditionally filter the columns array based on a condition (e.g., remove the "Action" and "_id" field)
  const createNewColumns = columns.filter(column => column.field !== "action" && column.field !== "_id")
  const noActionColumns = columns.filter(column => column.field !== "action")
  
  const handleEdit = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setOpenModal(false);
  };

  const handleRemove = (rowId) => {
    setrecordID(rowId)
    setOpen(true)
  };

  const handleRemoveDialog = ()=>{
    removeProduct(recordID, setAlert, setOpen)
  }

  const handleCreate = ()=>{
    setSelectedRow(null);
    setOpenModal(true);
  }

  const handleFormSubmit = (values) => {
    handleFormSubmitHook(values, setAlert, setOpenModal)
  };

  const handleCloseAlert = ()=>{
    setAlert(null)
  }

  return (
    <>
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="PRODUCTS" subtitle="List of product items." />

        <Box>
          <CreateNewButton handleCreate={handleCreate}/>
        </Box>
      </Box>
      <TableList columns={columns} data={storeProductsData ? storeProductsData : []}/>
    </Box>

    {alert && <AlertBox handleCloseAlert={handleCloseAlert} alert={alert} />}
    {open && <DialogBox open={open} setOpen={setOpen} handleRemove={handleRemoveDialog} />}
    

    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: '70vw',
          height: '90vh',
        }}
      >
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          <CloseIcon />
        </IconButton>
        
        <Box  sx={{ gridColumn: "span 4" }} >
          <UpdateForm
            initialValues={selectedRow ? selectedRow : null} 
            columns={selectedRow ? noActionColumns : createNewColumns}
            handleFormSubmit={handleFormSubmit}
            tableName={tableName}
          />
        </Box>
      </Box>
    </Modal>
    </>
  );
};

export default Products;
