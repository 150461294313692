import { Box, Button, TextField, Autocomplete, IconButton, Typography, useTheme, } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
// import Header from "../components/Header";
import ProductSelect from "./productSelect";
import Invoice from "./invoice";
import { useEffect, useState } from "react";
import Topbar from "../scenes/global/Topbar";
import TableList from "./tableList";
import { red } from "@mui/material/colors";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useTransactionHook from "../hooks/useTransactionHook";
import AlertBox from "../components/AlertBox";
import useUserHooks from "../hooks/useUserHooks";
import useProductHooks from "../hooks/useProductHooks";
import { useSelector } from "react-redux";
import { tokens } from "../theme";

const POSPortal = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const userInfo = useSelector((state) => state?.user.userInfo);

  const { createTransaction } = useTransactionHook()
  const { storeProductsData } = useProductHooks();
  const { allData, defaultUser, currentUser } = useUserHooks()

  const [data, setData] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [alert, setAlert] = useState(null);

  const [errors, setErrors] = useState({});

  // Get the current date
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString(); // You can customize the date format
  const formattedDateTime = currentDate.toLocaleString();
  
  // Calculate the total amount
  const totalAmount = data.reduce((total, item) => total + item.quantity * item.unitPrice, 0);

  useEffect(()=>{
    if(defaultUser !== undefined){
      setCustomer(defaultUser)
    }
  },[defaultUser])


  const columns = [
    { field: "name",  headerName: "Name",   flex: 1,  cellClassName: "name-column--cell", },
    { field: "quantity",  headerName: "Quantity",   flex: 1,  },
    { field: "unitPrice",  headerName: "Price",   flex: 1,  
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          ₱ {params.row.unitPrice}
        </Typography>
      ),
    },
    { field: "amount",  headerName: "Amount",   flex: 1,  
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          ₱ {params.row.amount}
        </Typography>
      ),
    },
    { field: "action",  headerName: "Action",   flex: 0.5,  
      renderCell: ({ row, row: { id } }) => {
        return (
          <IconButton
            aria-label="delete"
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => handleRemove(id)}
            sx={{
              "&:hover": {
                background: "none",
                color: red[500], // Use the red color from MUI
                transform: "scale(1.1)",
                transition: "0.3s",
              },
            }}
          >
            <DeleteForeverIcon fontSize="inherit" />
          </IconButton>
        );
      },
    },
  ];

  const handleRemove = (rowId) => {
    // Create a copy of the data array without the item to be removed
    const updatedData = data.filter((item, index) => item.id !== rowId);
    setData(updatedData);
  };

  const handleCheckout = () =>{
    const isDataValid = handleValidation();

    if (isDataValid && data.length !== 0 ) {
      var checkout = {
        customer : customer,
        store: userInfo.store,
        product_item : data,
        date : formattedDateTime,
        total_amount : totalAmount,
        created_by : currentUser ? currentUser : {}
      }
      // console.log(checkout)
      // console.log(JSON.stringify(checkout))
      // register Transaction
      createTransaction(checkout, setAlert, setData, setCustomer)

    }

  }

  const handleValidation = ()=>{
    const validationErrors = {};
    if (!customer) {
      validationErrors.customer = "Customer is required.";
    }
    if(data.length === 0){
      validationErrors.product = "Product list is empty.";
    }
    setErrors(validationErrors);
    // Check if there are any validation errors
    return Object.keys(validationErrors).length === 0;
  }

  const handleCloseAlert = ()=>{
    setAlert(null)
  }

  return (
    <Box 
      width="90%"
      height="100vh"
    >
      <Box>
        <Topbar />
        {alert && <AlertBox handleCloseAlert={handleCloseAlert} alert={alert} />}
      </Box>
      <Box 
        display="flex"
        justifyContent="space-between"  // This aligns the Box to the right
      >
        
        
        <Box  m="20px" width="70%">
          {/* <Header subtitle="Create a New Order" /> */}
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

          {/* 
          Customer Section
          */}
          <Autocomplete
            disablePortal
            sx={{ gridColumn: "span 1" }}
            fullWidth
            options={allData ? allData : []}
            value={customer}
            onChange={(event, newValue) => {
              setCustomer(newValue)
              setErrors({...errors, customer : ""});
            }}
            getOptionLabel={(option) => option.fullname ? option.fullname : option.email} // Adjust to match your data structure
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.fullname ? option.fullname : option.email}
              </Box>
            )}
            renderInput={(params) => 
              <TextField 
                {...params} 
                label="Choose Customer"                 
                variant="filled"
                error={!!errors.customer} // Set error state based on error message
                helperText={errors.customer} // Display the error message
              />
              }
          />

          {/* 
            Product Section
          */}
          <Box
            sx={{ gridColumn: "span 3", width: "100%" }}
          >
            <ProductSelect
              InputLabel="Select Products"
              options={storeProductsData ? storeProductsData : []}
              setData={setData}
              data={data}
              errors={errors}
              setErrors={setErrors}
            />
          </Box>

          {/* 
            Table Section
          */}
          <Box
            sx={{ gridColumn: "span 4", width: "100%" }}
          >
            <TableList columns={columns} data={data}/>
          </Box>
                  
          </Box>
        </Box>

        {/* 
          Invoice
        */}
        <Box m="20px 0 0 0" width="28%">
          <Invoice customer={customer} data={data} date={formattedDate} totalAmount={totalAmount} store={userInfo?.store}/>

          <Button onClick={handleCheckout} sx={{ margin: "50px 0 0 0", }} fullWidth color="secondary" variant="contained">
            CHECKOUT
          </Button>

        </Box>


      </Box>
    </Box>
  );
};

export default POSPortal;
