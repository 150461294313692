import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import useTransactionHook from "../../hooks/useTransactionHook";
import StoreIcon from "../../icons/StoreIcon";

const RecentTransactions = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { recentData } = useTransactionHook()

  return (
    recentData && recentData.length > 0 ? (
        recentData.map((transaction, i) => (
            <Box
            key={transaction._id}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
            
            >
            <Box>
                <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
                >
                <StoreIcon field={transaction.store}/>
                </Typography>
                <Typography color={colors.grey[100]}>
                    {transaction.customer.fullname}
                </Typography>
            </Box>
            <Box color={colors.grey[100]}>{transaction.created_on}</Box>
            <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
            >
                ₱{Number(transaction.total_amount).toLocaleString()}
            </Box>
            </Box>
        ))
    ) : (
        <Typography>No recent transactions found</Typography>
    )
  );
};

export default RecentTransactions;


