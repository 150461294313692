import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export default function DialogBox({open, setOpen, handleRemove}) {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Removal Confirmation"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure to remove the selected item ? 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button 
          onClick={handleRemove} 
          autoFocus 
          color="secondary" 
          variant="contained"
          sx={{
            "&:hover": {
              background: red[500], // Use the red color from MUI
              color: 'white', 
              transform: "scale(1.1)",
              transition: "0.3s",
            },
          }}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}