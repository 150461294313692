import { useState } from "react";
import { Modal, Box, useTheme, IconButton  } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import FormModal from "./updateModal";


const TableList = ({columns, data}) => {

  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const getRowId = (row) => row._id;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // const handleActionClick = (row) => {
  //   setSelectedRow(row);
  //   setOpenModal(true);
  // };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setOpenModal(false);
  };


  return (
    <>
      <Box
        m="0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns.filter((column) => !column.hide)}
          components={{ Toolbar: GridToolbar }}
          checkboxSelection={false}
          sx={{
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
          getRowId={getRowId} // Pass the getRowId function
          // onRowClick={(params) => handleActionClick(params.row)}
          selectionModel={selectedRow ? [selectedRow._id] : []}
        />
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: '70vw',
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
            }}
          >
            <CloseIcon />
          </IconButton>

          <FormModal initialValues={selectedRow} columns={columns}/>

        </Box>
       
      </Modal>
    </>
  );
};

export default TableList;
