import * as yup from "yup";
import useUserHooks from "./useUserHooks";
import useProductHooks from "./useProductHooks";

const useValidationHooks = () => { 

    const { hasDuplicateEmail } = useUserHooks()
    const { hasDuplicateField } = useProductHooks()

    const phoneNumberRegex = /^(?:\+63|0)[1-9]\d{9}$/;

    const createUserValidations = yup.object().shape({
        // username: yup.string().required("required"),
        fullname: yup.string().required("required"),
        address: yup.string().required("required"),
        account_type: yup.string().required("required"),
        email: yup
            .string()
            .email("Invalid email")
            .test('duplicateEmail', 'Email already exists', function (value) {
            return !hasDuplicateEmail(value);
            })
            .required("required"),
        phone_number: yup
            .string()
            .required("required")
            .matches(phoneNumberRegex,"Phone number is not valid"),
        password: yup
            .string()
            .required("required")
            .min(8, "Password must be at least 8 characters long"),
        passwordCheck: yup
            .string()
            .required("required")
            .oneOf([yup.ref("password")], "Passwords must match"),

    });

    const updateUserValidations = yup.object().shape({
        fullname: yup.string().required("required"),
        address: yup.string().required("required"),
        phone_number: yup
            .string()
            .required("required")
            .matches(phoneNumberRegex,"Phone number is not valid"),
        account_type: yup.string().required("required"),
    });


    const productValidations = (initialFormValues) => {

        const createValidation = yup.object().shape({
            description: yup.string().required("required"),
            sku: yup.string().required("required"),
            quantity: yup.number().required("required"),
            price: yup.number().required("required"),
            category: yup.string().required("required"),
            store: yup.string().required("required"),
            product_name: yup.string()
                .test('duplicateName', 'Name already exists in this store.', function (value) {
                return !hasDuplicateField('product_name', value);
                })
                .required("required"),
            product_code: yup.string()
                .test('duplicateCode', 'Code already exists in this store.', function (value) {
                    return !hasDuplicateField('product_code', value);
                })
                .required("required"),
        });

        const updateValidation = yup.object().shape({
            description: yup.string().required("required"),
            sku: yup.string().required("required"),
            quantity: yup.number().required("required"),
            price: yup.number().required("required"),
            category: yup.string().required("required"),
            store: yup.string().required("required"),
        });
      
        // If create is false, add the product_code validation
        const schemaWithCode = initialFormValues._id
          ? updateValidation
          : createValidation
      
        return schemaWithCode;
    };
      

    return {
        createUserValidations,
        updateUserValidations,
        productValidations,
    };

}

export default  useValidationHooks;