import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTransactions } from "../services/transactionAPIcalls";

const useDashboardHooks = () => { 

    const dispatch = useDispatch();
    const [allTransactionData, setAllTransactionData] = useState();

    const [lineGraphData, setLineGraphData] = useState()
    const [barGraphData, setBarGraphData] = useState()
    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(()=>{
        dispatch(getTransactions()) // Call getTransactions to dispatch the action
        .then(response=>{
            const filterMonth = filterThisMonth(response)
            setTotalAmount(thisMonthTotal(response))
            setLineGraphData(groupedProductItems(filterMonth, "amount"))
            setBarGraphData(groupedProductItems(filterMonth, "quantity"))
            
            setAllTransactionData(response) // Update the state with the response data
        })
        .catch(error => {
            console.error(error);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const filterThisMonth =(data)=>{
      // Assuming your data is stored in a variable named 'data'
      // const targetMonth = 10; // November
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns a zero-based index.

      const filteredData = data.filter(item => {
        const itemDate = new Date(item.date);
        const itemMonth = itemDate.getMonth() + 1;

        // return itemMonth === targetMonth;
        return itemMonth === currentMonth;
      });

      return filteredData
    }

    const groupedProductItems = (data, field)=>{
        // data -> get all transaction records

        const productItems = data.map(item => item.product_item);
        const allProductItems = productItems.flat();
        
        // Group the data by product name and store
        const groupedData = allProductItems.reduce((groups, item) => {
          const { name, store, amount, quantity } = item;
          const key = name;
        
          if (!groups[key]) {
            groups[key] = {
              name,
            };
          }
          
          if(field === 'quantity'){
            groups[key][store] = (groups[key][store] || 0) + parseInt(quantity, 10);
          }

          if(field === 'amount'){
            groups[key][store] = (groups[key][store] || 0) + parseInt(amount, 10);
          }
        
          return groups;
        }, {});
        
        // Transform the grouped data into the desired output format
        const output = Object.values(groupedData).map((group) => {
          const { name, ...amounts } = group;
          return { name, ...amounts };
        });

        return output
    }

    const getMonthlyTotals = (data)=>{
      const monthlyTotals = {};

      data.forEach(item => {
        const date = new Date(item.date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are 0-based, so we add 1

        const formattedMonth = `${year}-${String(month).padStart(2, '0')}`;
        
        if (monthlyTotals[formattedMonth]) {
          monthlyTotals[formattedMonth] += parseFloat(item.total_amount);
        } else {
          monthlyTotals[formattedMonth] = parseFloat(item.total_amount);
        }
      });
      return monthlyTotals
    }

    const thisMonthTotal = (data)=>{
      const monthlytotals = getMonthlyTotals(data)

      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are 0-based, so we add 1

      const formattedMonth = `${year}-${String(month).padStart(2, '0')}`;

      return monthlytotals[formattedMonth]
    }


    return {
        allTransactionData,
        lineGraphData,
        totalAmount,
        barGraphData
    };
};

export default useDashboardHooks;
