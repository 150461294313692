
import { useEffect, useState } from "react";
import Topbar from "../scenes/global/Topbar";
import Sidebar from "../scenes/global/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";

function PrivateLayout() {
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);

    const userInfo = useSelector((state) => state.user.userInfo);
    const navigate = useNavigate()

    useEffect(() => {
        // This code will execute after the initial render.
        if (userInfo.loaded !== null) {
            const userType = userInfo.account_type;
            // if not admin or manager
            if (userType !== "admin" && userType !== "manager") {
              navigate('/portal');
            } else {
              // The user is an admin, allow them to access the content.
            }
        }
    }, [userInfo, navigate]);

    return (
        <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Outlet />
            </main>
        </div>
        </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default PrivateLayout;
