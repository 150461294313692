import { Box, Button, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import useValidationHooks from "../../hooks/useValidationHooks";

const UpdateForm = ({initialValues, columns, handleFormSubmit, tableName}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { createUserValidations, updateUserValidations, productValidations } = useValidationHooks()

  const labels = {
    title: initialValues ? "UPDATE" : "CREATE FORM",
    subtitle : initialValues ? "Update details." : "Fill up details to create new record.",
    button: initialValues ? "UPDATE" : "CREATE"
  }

  const initialFormValues = {};
  columns.forEach((item) => {
    // Initialize each field with an empty string
    initialFormValues[item.field] = initialValues ? initialValues[item.field] || "" : "";
  });

  const validationHandler = ()=>{
    if(tableName === "products"){
      return productValidations(initialFormValues)
    }
    if(tableName === "users" && !initialValues){
      return createUserValidations
    }else{
      return updateUserValidations
    }
  }


  // Sort the columns based on the 'order' field, handling null values
  const sortedColumns = [...columns].sort((a, b) => (a.order || 0) - (b.order || 0));

  // console.log(sortedColumns)

  //handle ReadOnly fields
  const disableFields = (fieldname)=>{
    if(initialValues){ // update
      // if(fieldname === "email" || fieldname === "store"){
      if(fieldname === "email" || fieldname === "product_code" || fieldname === "product_name"){
        return true
      }
    }else{ // create
      return false
    }
  }

  return (
    <Box m="20px">
      <Header title={labels.title} subtitle={labels.subtitle} />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues ? {...initialFormValues, initialValues} : initialFormValues}
        validationSchema={validationHandler}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
                {sortedColumns.map((item)=> 
                  item.type === "image" ? (
                    <Box sx={{ gridColumn: "span 4" }} key={item.field}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const imageUrl = URL.createObjectURL(file);
                            handleChange({ target: { name: item.field, value: imageUrl } });
                          }
                        }}
                        style={{ display: "block", marginBottom: "10px" }}
                      />
                      <img
                        alt="profile-user"
                        width="200px"
                        height="200px"
                        src={values[item.field] || "../../assets/default.jpg"}
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  ) : item.field === "_id" ? (
                    // do not display _id field
                    <TextField
                      key={item.field}
                      fullWidth
                      variant="filled"
                      type="text"
                      label={item.headerName}
                      value={values[item.field]}
                      name={item.field}
                      InputProps={{
                          readOnly: true, // Make the TextField read-only
                      }}
                      sx={{ gridColumn: "span 2" , display: "none", fontSize: "30px" }}
                  />
                  // ) : item.field === "account_type" || item.field === "category" ? (
                  ) : item.options ? (
                    // do not display _id field
                    <FormControl 
                      key={item.field}
                      fullWidth
                      variant="filled"
                      sx={{ gridColumn: "span 2" }}
                    >
                      <InputLabel id="demo-simple-select-error-label">{item.headerName}</InputLabel>
                      <Select
                        labelId="demo-simple-select-error-label"
                        id="demo-simple-select"
                        name={item.field}
                        value={values[item.field]}
                        label={item.headerName}
                        onChange={handleChange}
                        error={!!touched[item.field] && !!errors[item.field]}
                        inputProps={{
                          readOnly: disableFields(item.field),
                        }}
                        // helperText={touched[item.field] && errors[item.field]}
                      >
                        {item.options.map((optionItem, index)=>(
                          <MenuItem key={index} value={optionItem.value}>{optionItem.label}</MenuItem>
                        ))}
                      
                      </Select>
                      <FormHelperText>{touched[item.field] && errors[item.field]}</FormHelperText>
                    </FormControl>
                  ) : (
                    <TextField
                      key={item.field}
                      fullWidth
                      variant="filled"
                      type={item.field === "password" || item.field === "passwordCheck" ? "password" : "text"}
                      label={item.headerName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[item.field]}
                      name={item.field}
                      InputProps={{
                        readOnly: disableFields(item.field),
                        disabled: disableFields(item.field),
                        startAdornment: item.field === "price" ? <InputAdornment position="start">₱</InputAdornment> : null,
                      }}
                      error={!!touched[item.field] && !!errors[item.field]}
                      helperText={touched[item.field] && errors[item.field]}
                      sx={{ gridColumn: "span 2", fontSize: "16px" }}
                    />
                  )
                )}

            </Box>
            <Box display="flex" justifyContent="end" mt="50px">
              <Button type="submit" color="secondary" variant="contained">
                {labels.button}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};





export default UpdateForm;
