import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockDataInvoices } from "../../data/mockData";
import Header from "../../components/Header";
import TableList from "../form/tableList";

const Invoices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "_id", headerName: "ID" },
    { field: "name", headerName: "Name", flex: 1, cellClassName: "name-column--cell", },
    { field: "phone", headerName: "Phone Number", flex: 1, },
    { field: "email", headerName: "Email", flex: 1, },
    { field: "cost", headerName: "Cost", flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          ${params.row.cost}
        </Typography>
      ),
    },
    { field: "date", headerName: "Date", flex: 1, },
  ];

  return (
    <Box m="20px">
      <Header title="INVOICES" subtitle="List of Invoice Balances" />
      <TableList columns={columns} data={mockDataInvoices} />
    </Box>
  );
};

export default Invoices;
