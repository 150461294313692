

import { React, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../../services/loginAPIcalls";

export default function Register () {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({});
    const [errorMessage, setError] = useState("");

    const handleSubmit = (e) =>{
        e.preventDefault()
        
        dispatch(registerUser(formData))
            .then(response=>{
                navigate("/login");
            })
            .catch(error => {
                console.log(error)
                setError(error.response.data.err)
            });
    }

    const handleChange = (e) => {
        setError('')
        const { name, value } = e?.target || {};

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return(
        <div id="register">
            <form onSubmit={handleSubmit}> 
                <div className="container login-wrap">
                    <h1>Create your account</h1>
                    
                    <div className="input-wrap">
                        <input 
                            placeholder="Full Name" 
                            type="text"
                            name="fullname"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-wrap">
                        <input 
                            placeholder="Email Address" 
                            type="email"
                            name="email"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-wrap">
                        <input 
                            placeholder="Password" 
                            type="password"
                            name="password"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-wrap">
                        <input 
                            placeholder="Confirm Password" 
                            type="password"
                            name="passwordCheck"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {errorMessage && <div className="errorMessage">{errorMessage}</div>}

                    <button type="submit" className="input-wrap continue-btn">Continue</button>
                    <div className="signup-wrap">
                        <p>Already have an account? <Link className="sign-up" to={'/login'}>Log in</Link></p>
                    </div>
                    
                </div>
            </form>
        </div>
    )
}

