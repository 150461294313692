import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import useLoginApiHooks from "../../hooks/useLoginHook";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useSelector } from "react-redux";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const [adminIconVisible, setAdminIconVisible] = useState(true)
  const [portalIconVisible, setPortalIconVisible] = useState(true)

  const userInfo = useSelector((state) => state.user.userInfo);

  const { logout } = useLoginApiHooks();

  const navigate = useNavigate()

  useEffect(() => {
    // This code will execute after the initial render.
    // Get the current path from the URL
    const currentPath = window.location.pathname;

    // Check if the current path contains /admin and hide the admin icon
    if (currentPath.includes('/admin')) {
      setAdminIconVisible(false);
    }

    // Check if the current path contains /portal and hide the pos icon
    if (currentPath.includes('/portal')) {
      setPortalIconVisible(false);
    }
  }, []);

  const isAdmin = ()=>{
    if(userInfo && (userInfo.account_type === "admin" || userInfo.account_type === "manager")){
      return true
    }else{
      return false
    }
  }

  const handleNavigation = (url) => {
    switch (url) {
      case 'admin':
        navigate('/admin');
        break;
      case 'portal':
        navigate('/portal');
        break;
      default:
        break;
    }
  }


  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box display="flex" alignItems="center" color={colors.primary[100]}>
        <Typography variant="h4" color={colors.greenAccent[400]}>
          Hello {userInfo?.fullname}
        </Typography>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {isAdmin() && adminIconVisible && <Tooltip title="Admin">
          <IconButton onClick={()=>handleNavigation('admin')}>
            <AdminPanelSettingsIcon />
          </IconButton>
        </Tooltip>}
        
        {portalIconVisible && <Tooltip title="POS Portal">
          <IconButton onClick={()=>handleNavigation('portal')}>
            <PointOfSaleIcon />
          </IconButton>
        </Tooltip>}
        
        <Tooltip title="Notifications">
          <IconButton>
            <NotificationsOutlinedIcon />
          </IconButton>
        </Tooltip>
        
        <Tooltip title="Settings">
          <IconButton>
            <SettingsOutlinedIcon />
          </IconButton>
        </Tooltip>
        
        {/* <Tooltip title="Profile">
          <IconButton>
            <PersonOutlinedIcon />
          </IconButton>
        </Tooltip> */}
        
        <Tooltip title="Log out">
          <IconButton onClick={logout}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
        
      </Box>
    </Box>
  );
};

export default Topbar;
